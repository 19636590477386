import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../components/Home";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about-us",
    name: "about-us",
    component: () => import("../components/AboutUs")
  },
  {
    path: "/service-landlords",
    name: "service-landlords",
    component: () => import("../components/OurLandlords")
  },
  {
    path: "/service-tenants",
    name: "service-tenants",
    component: () => import("../components/OurTenants")
  },
  {
    path: "/service-business",
    name: "service-business",
    component: () => import("../components/OurBusiness")
  },
  {
    path: "/admin-property",
    name: "admin-property",
    component: () => import("../components/AdminPropertyView")
  },
  {
    path: "/admin-locality",
    name: "admin-locality",
    component: () => import("../components/AdminLocalityView")
  },
  {
    path: "/admin-city",
    name: "admin-city",
    component: () => import("../components/AdminCityView")
  },
  {
    path: "/property-finder/:offer_type",
    name: "property-finder",
    component: () => import("../components/PropertyFinder"),
    props: true
  },
  {
    path: "/property-details/:property_ref",
    name: "property-details",
    component: () => import("../components/PropertyDetails"),
    props: true
  },
  {
    path: "/property-for-rent",
    name: "property-for-rent",
    component: () => import("../components/PropertyRentView")
  },
  {
    path: "/property-for-sale",
    name: "property-for-sale",
    component: () => import("../components/PropertySaleView")
  },
  {
    path: "/valuation-request",
    name: "valuation-request",
    component: () => import("../components/PropertyValuationView")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
