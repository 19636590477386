<template>
  <div>
    <!-- Main Slider Section -->
    <section id="slider">
      <div class="tp-banner-container">
        <div class="tp-banner">
          <ul>
            <li
              data-transition="fade"
              data-slotamount="7"
              data-masterspeed="2000"
              data-thumb="/assets/img/slider/asamai_properties_bg_011.jpg"
              data-delay="10000"
            >
              <!-- MAIN IMAGE -->
              <img
                src="/assets/img/dummy.png"
                alt="laptopmockup_sliderdy"
                data-lazyload="/assets/img/slider/asamai_properties_bg_011.jpg"
                data-bgposition="center center"
                data-duration="12000"
                data-ease="Power0.easeInOut"
                data-bgfit="cover"
                data-bgfitend="100"
                data-bgpositionend="center center"
              />
              <!-- LAYERS 2 -->
              <div
                class="tp-caption largeHeadingWhite sfl str tp-resizeme start"
                data-x="center"
                data-y="center"
                data-voffset="-150"
                data-speed="1200"
                data-start="950"
                data-easing="easeInOutExpo"
                data-splitin="none"
                data-splitout="none"
                data-elementdelay="0.1"
                data-endelementdelay="0.1"
                data-endspeed="800"
                data-endeasing="easeInOutExpo"
              >
                <h1
                  style="text-align: center; min-height: 0px; min-width: 0px; line-height: 94px; border-width: 0px; margin: 0px 0px 20px; padding: 0px; letter-spacing: 2px; font-size: 40px; text-transform: uppercase; font-weight: 700; color: #fff;"
                >
                  Welcome to your local
                </h1>
              </div>
              <div
                class="tp-caption largeHeadingWhite sfl str tp-resizeme start"
                data-x="center"
                data-y="center"
                data-voffset="-80"
                data-speed="1200"
                data-start="950"
                data-easing="easeInOutExpo"
                data-splitin="none"
                data-splitout="none"
                data-elementdelay="0.1"
                data-endelementdelay="0.1"
                data-endspeed="800"
                data-endeasing="easeInOutExpo"
              >
                <h1
                  style="text-align: center; min-height: 0px; min-width: 0px; line-height: 94px; border-width: 0px; margin: 0px 0px 20px; padding: 0px; letter-spacing: 2px; font-size: 40px; text-transform: uppercase; font-weight: 700; color: #fff;"
                >
                  letting Agent in
                  <span style="color:yellow!important; font-size: 40px;"
                    >Leicester</span
                  >
                </h1>
              </div>
              <!-- LAYERS 4 -->
              <div
                class="tp-caption sfb tp-resizeme start"
                data-x="center"
                data-hoffset="0"
                data-y="center"
                data-voffset="90"
                data-speed="1200"
                data-start="1750"
                data-easing="easeInOutExpo"
                data-splitin="none"
                data-splitout="none"
                data-elementdelay="0.1"
                data-endelementdelay="0.1"
                data-endspeed="300"
              >
                <router-link class="btn btn-common btn-lg" to="/about-us"
                  >More...</router-link
                >
              </div>
            </li>

            <li
              data-transition="fade"
              data-slotamount="7"
              data-masterspeed="2000"
              data-thumb="/assets/img/slider/asamai_properties_bg_013.jpg"
              data-delay="10000"
            >
              <!-- MAIN IMAGE -->
              <img
                src="/assets/img/dummy.png"
                alt="laptopmockup_sliderdy"
                data-lazyload="/assets/img/slider/asamai_properties_bg_013.jpg"
                data-bgposition="center center"
                data-duration="12000"
                data-ease="Power0.easeInOut"
                data-bgfit="cover"
                data-bgfitend="100"
                data-bgpositionend="center center"
              />
              <!-- LAYERS 2 -->
              <div
                class="tp-caption largeHeadingWhite sfl str tp-resizeme start"
                data-x="center"
                data-y="center"
                data-voffset="-40"
                data-speed="1200"
                data-start="950"
                data-easing="easeInOutExpo"
                data-splitin="none"
                data-splitout="none"
                data-elementdelay="0.1"
                data-endelementdelay="0.1"
                data-endspeed="800"
                data-endeasing="easeInOutExpo"
              >
                <h1
                  style="text-align: center; min-height: 0px; min-width: 0px; line-height: 94px; border-width: 0px; margin: 0px 0px 20px; padding: 0px; letter-spacing: 2px; font-size: 50px; text-transform: uppercase; font-weight: 700; color: #fff;"
                >
                  CALL US NOW ON:
                  <span style="color: #75D147; font-size: 50px;"
                    >0772 336 3184</span
                  >
                </h1>
              </div>
              <!-- LAYERS 4 -->
              <div
                class="tp-caption sfb tp-resizeme start"
                data-x="center"
                data-hoffset="0"
                data-y="center"
                data-voffset="90"
                data-speed="1200"
                data-start="1750"
                data-easing="easeInOutExpo"
                data-splitin="none"
                data-splitout="none"
                data-elementdelay="0.1"
                data-endelementdelay="0.1"
                data-endspeed="300"
              >
                <router-link class="btn btn-common btn-lg" to="/about-us"
                  >More...</router-link
                >
              </div>
            </li>

            <li
              data-transition="fade"
              data-slotamount="7"
              data-masterspeed="2000"
              data-thumb="/assets/img/slider/asamai_properties_bg_012.jpg"
              data-delay="10000"
            >
              <!-- MAIN IMAGE -->
              <img
                src="/assets/img/dummy.png"
                alt="laptopmockup_sliderdy"
                data-lazyload="/assets/img/slider/asamai_properties_bg_012.jpg"
                data-bgposition="center center"
                data-duration="12000"
                data-ease="Power0.easeInOut"
                data-bgfit="cover"
                data-bgfitend="100"
                data-bgpositionend="center center"
              />
              <!-- LAYERS 1-->
              <div
                class="tp-caption sft skewtotop tp-resizeme title start"
                data-x="center"
                data-y="center"
                data-voffset="-120"
                data-speed="800"
                data-start="950"
                data-easing="Power4.easeOut"
                data-splitin="none"
                data-splitout="none"
                data-elementdelay="0.01"
                data-endelementdelay="0.1"
                data-endspeed="1000"
                data-endeasing="Power4.easeIn"
                style="z-index: 11;"
              >
                <h3
                  style="text-align: center; min-height: 0px; min-width: 0px; line-height: 94px; border-width: 0px; margin: 0px 0px 20px; padding: 0px; letter-spacing: 2px; font-size: 24px; text-transform: uppercase; font-weight: 700; color: #fff;"
                >
                  we are transparent and honest
                </h3>
              </div>
              <!-- LAYERS 2 -->
              <div
                class="tp-caption largeHeadingWhite sfl str tp-resizeme start"
                data-x="center"
                data-y="center"
                data-voffset="-40"
                data-speed="1200"
                data-start="950"
                data-easing="easeInOutExpo"
                data-splitin="none"
                data-splitout="none"
                data-elementdelay="0.1"
                data-endelementdelay="0.1"
                data-endspeed="800"
                data-endeasing="easeInOutExpo"
              >
                <h1
                  style="text-align: center; min-height: 0px; min-width: 0px; line-height: 94px; border-width: 0px; margin: 0px 0px 20px; padding: 0px; letter-spacing: 2px; font-size: 50px; text-transform: uppercase; font-weight: 700; color: #fff;"
                >
                  Get started with us now
                </h1>
              </div>
              <!-- LAYERS 4 -->
              <div
                class="tp-caption sfb tp-resizeme start"
                data-x="center"
                data-hoffset="0"
                data-y="center"
                data-voffset="90"
                data-speed="1200"
                data-start="1750"
                data-easing="easeInOutExpo"
                data-splitin="none"
                data-splitout="none"
                data-elementdelay="0.1"
                data-endelementdelay="0.1"
                data-endspeed="300"
              >
                <router-link class="btn btn-common btn-lg" to="/about-us"
                  >More...</router-link
                >
              </div>
            </li>

            <li
              data-transition="fade"
              data-slotamount="7"
              data-masterspeed="2000"
              data-thumb="/assets/img/slider/asamai_properties_bg_006.jpg"
              data-delay="10000"
            >
              <!-- MAIN IMAGE -->
              <img
                src="/assets/img/dummy.png"
                alt="laptopmockup_sliderdy"
                data-lazyload="/assets/img/slider/asamai_properties_bg_006.jpg"
                data-bgposition="right top"
                data-duration="12000"
                data-ease="Power0.easeInOut"
                data-bgfit="cover"
                data-bgfitend="100"
                data-bgpositionend="center center"
              />
              <!-- LAYERS 1-->
              <div
                class="tp-caption sft skewtotop tp-resizeme title start"
                data-x="center"
                data-y="center"
                data-voffset="-120"
                data-speed="800"
                data-start="950"
                data-easing="Power4.easeOut"
                data-splitin="none"
                data-splitout="none"
                data-elementdelay="0.01"
                data-endelementdelay="0.1"
                data-endspeed="1000"
                data-endeasing="Power4.easeIn"
                style="z-index: 11;"
              >
                <h3
                  style="text-align: center; min-height: 0px; min-width: 0px; line-height: 94px; border-width: 0px; margin: 0px 0px 20px; padding: 0px; letter-spacing: 2px; font-size: 24px; text-transform: uppercase; font-weight: 700; color: yellow;"
                >
                  Landlord, Tenant or Buyer ?
                </h3>
              </div>
              <!-- LAYERS 2 -->
              <div
                class="tp-caption largeHeadingWhite sfl str tp-resizeme start"
                data-x="center"
                data-y="center"
                data-voffset="-40"
                data-speed="1200"
                data-start="950"
                data-easing="easeInOutExpo"
                data-splitin="none"
                data-splitout="none"
                data-elementdelay="0.1"
                data-endelementdelay="0.1"
                data-endspeed="800"
                data-endeasing="easeInOutExpo"
              >
                <h1
                  style="text-align: center; min-height: 0px; min-width: 0px; line-height: 94px; border-width: 0px; margin: 0px 0px 20px; padding: 0px; letter-spacing: 2px; font-size: 50px; text-transform: uppercase; font-weight: 700; color: #fff;"
                >
                  Call us now !
                </h1>
              </div>
              <!-- LAYERS 4 -->
              <div
                class="tp-caption sfb tp-resizeme start"
                data-x="center"
                data-hoffset="0"
                data-y="center"
                data-voffset="90"
                data-speed="1200"
                data-start="1750"
                data-easing="easeInOutExpo"
                data-splitin="none"
                data-splitout="none"
                data-elementdelay="0.1"
                data-endelementdelay="0.1"
                data-endspeed="300"
              >
                <router-link class="btn btn-common btn-lg" to="/about-us"
                  >More...</router-link
                >
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <!-- Main Slider Section End-->
    <PropertyFinder baseUrl="/PropertyFinder/"></PropertyFinder>
    <!-- Counter Section Start -->
    <section id="counter">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-sm-6 col-xs-12">
            <div class="counting">
              <div class="desc">
                <div class="icon">
                  <i class="icon-home"></i>
                  <h3 class="counter">250</h3>
                </div>
                <p>Property Rented</p>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-xs-12">
            <div class="counting">
              <div class="desc">
                <div class="icon">
                  <i class="icon-key"></i>
                  <h3 class="counter">54</h3>
                </div>
                <p>Property Sold</p>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-xs-12">
            <div class="counting">
              <div class="desc">
                <div class="icon">
                  <i class="icon-emotsmile"></i>
                  <h3 class="counter">300</h3>
                </div>
                <p>Happy Customers</p>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-xs-12">
            <div class="counting">
              <div class="desc">
                <div class="icon">
                  <i class="icon-cup"></i>
                  <h3 class="counter">1</h3>
                </div>
                <p>Awards Win</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Counter Section End -->
    <section id="contact-us">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <h2 class="section-title2">Leicester office location</h2>
            <!-- <div class="title">
              <h3>
                <span style="color: #ACDE4F;">Our </span
                ><span style="color: #0a1f3f;">Leicester office</span>
              </h3>
            </div> -->
          </div>
        </div>
      </div>

      <div
        class="googlemap"
        data-latlng="52.628460,-1.149110"
        data-scrollwheel="false"
        data-icon="/assets/img/map-marker.png"
        data-zoom="17"
        data-theme="Default"
      >
        <div class="marker-content">
          <h5>ASAMAI PROPERTIES</h5>
          <p>
            12 Briton Street<br />
            LE3 0AA<br />
            Leicester<br />
            United Kingdom<br />
          </p>
        </div>
      </div>
      <div class="contact">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-10 col-md-offset-1">
              <contact></contact>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  components: {
    Contact: () => import("./Contact"),
    PropertyFinder: () => import("./PropertyFinder")
  },
  mounted() {
    window
      .$(".tp-banner")
      .show()
      .revolution({
        dottedOverlay: "none",
        delay: 9000,
        startwidth: 1170,
        startheight: 540,
        hideThumbs: 200,
        thumbWidth: 100,
        thumbHeight: 50,
        thumbAmount: 5,
        navigationType: "bullet",
        navigationArrows: "solo",
        navigationStyle: "preview4",
        touchenabled: "on",
        onHoverStop: "on",
        swipe_velocity: 0.7,
        swipe_min_touches: 1,
        swipe_max_touches: 1,
        drag_block_vertical: false,
        parallax: "mouse",
        parallaxBgFreeze: "on",
        parallaxLevels: [7, 4, 3, 2, 5, 4, 3, 2, 1, 0],
        keyboardNavigation: "off",
        navigationHAlign: "center",
        navigationVAlign: "bottom",
        navigationHOffset: 0,
        navigationVOffset: 20,
        soloArrowLeftHalign: "left",
        soloArrowLeftValign: "center",
        soloArrowLeftHOffset: 20,
        soloArrowLeftVOffset: 0,
        soloArrowRightHalign: "right",
        soloArrowRightValign: "center",
        soloArrowRightHOffset: 20,
        soloArrowRightVOffset: 0,
        shadow: 0,
        fullWidth: "on",
        fullScreen: "off",
        spinner: "spinner1",
        stopLoop: "off",
        stopAfterLoops: -1,
        stopAtSlide: -1,
        shuffle: "off",
        autoHeight: "off",
        forceFullWidth: "off",
        hideThumbsOnMobile: "off",
        hideNavDelayOnMobile: 1500,
        hideBulletsOnMobile: "off",
        hideArrowsOnMobile: "off",
        hideThumbsUnderResolution: 0,
        hideSliderAtLimit: 0,
        hideCaptionAtLimit: 0,
        hideAllCaptionAtLilmit: 0,
        startWithSlide: 0,
        fullScreenOffsetContainer: ""
      });
    window.initMap();
  }
};
</script>
