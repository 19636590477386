import VeeValidate from "vee-validate";
import moment from "moment";

const dictionary = {
  en: {
    messages: {
      required: () => "This field is required.",
      numeric: () => "This field may only contain numeric characters.",
      email: () => "This field must be a valid email.",
      alpha: () => "This field may only contain alphabetic characters.",
      decimal: (_field, args) =>
        `This field must be numeric and may contain ${args[0]} decimal points.`,
      between: (_field, args) =>
        `This field must be between ${args[0]} and ${args[1]}`,
      date_between: (_field, args) =>
        `Date must be between ${args[0]} and ${args[1]}`,
      max: (_field, args) =>
        `This field may not be greater than ${args[0]} characters`,
      min: (_field, args) =>
        `This field must be at least ${args[0]} characters`,
      regex: (_field, args) => `${args[1]}`,
      date_format: (_field, args) => {
        if (args == "DD-MMM-YYYY") {
          return "Date must be DD-MMM-YYYY format, example: 24-Dec-1970.";
        } else if (args == "DD-MMM-YYYY HH:mm") {
          return "Date must be DD-MMM-YYYY HH:mm format, example: 24-Dec-1970 13:20.";
        } else if (args == "HH:mm") {
          return "Time must be HH:mm format, example: 13:20.";
        }
        return "Invalid date format argument";
      }
    }
  }
};
const current_date_compare = {
  getMessage: (_field, _args, data) => {
    return data.msg;
  },
  validate: (value, args) => {
    let rData = { valid: true, data: { msg: "" } };
    if (value === null || value === undefined || args == undefined) {
      rData.valid = false;
      return rData;
    }
    if (["lt", "gt", "eq", "lteq", "gteq"].indexOf(args.opt) == -1) {
      throw new Error(
        `current_date_compare validator has invalid parameter: ${args.opt}`
      );
    }
    let dVal = moment(value, "DD-MMM-YYYY").diff(
      moment().format("DD-MMM-YYYY"),
      "days"
    );
    if (args.opt == "lt" && dVal >= 0) {
      rData.data.msg = `The date entred: ${value}, must be before today's date: ${moment().format(
        "DD-MMM-YYYY"
      )}`;
      rData.valid = false;
      return rData;
    }
    if (args.opt == "lteq" && dVal > 0) {
      rData.data.msg = `The date entred: ${value}, must be on or before today's date: ${moment().format(
        "DD-MMM-YYYY"
      )}`;
      rData.valid = false;
      return rData;
    }
    if (args.opt == "gt" && dVal <= 0) {
      rData.data.msg = `The date entred: ${value}, must be after today's date: ${moment().format(
        "DD-MMM-YYYY"
      )}`;
      rData.valid = false;
      return rData;
    }
    if (args.opt == "gteq" && dVal < 0) {
      rData.data.msg = `The date entred: ${value}, must be on or after today's date: ${moment().format(
        "DD-MMM-YYYY"
      )}`;
      rData.valid = false;
      return rData;
    }
    if (args.opt == "eq" && dVal != 0) {
      rData.data.msg = `The date entred: ${value}, must be same as today's date: ${moment().format(
        "DD-MMM-YYYY"
      )}`;
      rData.valid = false;
      return rData;
    }
    return rData;
  }
};
const valid_name = {
  getMessage: (_field, _args, data) => {
    return data.msg;
  },
  validate: value => {
    let rData = { valid: true, data: { msg: "" } };
    if (value !== null && value !== undefined) {
      let re = new RegExp("^( ?[a-zA-Z]+([-_'.][a-zA-Z]+)*)+$");
      if (!re.test(value)) {
        rData.data = {
          msg: `The name entred: ${value} has invalid character(s)`
        };
        rData.valid = false;
        return rData;
      }
    }
    return rData;
  }
};
const valid_uk_postcode = {
  getMessage: () => {
    return "Must be valid UK postcode.";
  },
  validate: value => {
    if (value === null || value === undefined) {
      return false;
    }
    let re = new RegExp(
      "^(([gG][iI][rR] 0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkpstuwA-HJKPSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) [0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$"
    );
    if (!re.test(value)) {
      return false;
    }
    return true;
  }
};
const age_check = {
  getMessage: (_field, _args, data) => {
    return data.msg;
  },
  validate: (value, args) => {
    let rData = { valid: true, data: { msg: "" } };
    if (value === null || value === undefined || args == undefined) {
      rData.valid = false;
      return rData;
    }
    if (["Maximum", "Minimum"].indexOf(args.opt) == -1) {
      throw new Error(`age_check validator has invalid parameter: ${args.opt}`);
    }
    let dVal = moment(moment().format("DD-MMM-YYYY")).diff(
      moment(value, "DD-MMM-YYYY"),
      "years",
      true
    );
    let dText = Math.floor(dVal).toString();
    if (args.opt == "Minimum" && dVal < args.ageLimit) {
      rData.data.msg = `Age calculated is: ${dText}, age must be at least: ${args.ageLimit} years`;
      rData.valid = false;
      return rData;
    } else if (args.opt == "Maximum" && dVal > args.ageLimit) {
      rData.data.msg = `Age calculated is: ${dText}, age must be: ${args.ageLimit} years or below`;
      rData.valid = false;
      return rData;
    }
    return rData;
  }
};
const lookup_compare = {
  getMessage: (_field, _args, data) => {
    return data.msg;
  },
  validate: (value, args) => {
    let rData = { valid: true, data: { msg: "" } };
    if (value === null || value === undefined || args == undefined) {
      rData.valid = false;
      return rData;
    }
    if (args.targetValue == undefined) {
      return rData;
    }
    if (["lt", "gt", "eq", "noteq", "lteq", "gteq"].indexOf(args.opt) == -1) {
      throw new Error(
        `lookup_compare validator has invalid parameter:${args.opt}`
      );
    }
    let dVal;
    let tVal;
    if (args.dataType == "date") {
      if (args.adjuster === undefined) {
        dVal = moment(value, "DD-MMM-YYYY").diff(
          moment(args.targetValue, "DD-MMM-YYYY"),
          "days"
        );
        tVal = args.targetValue;
      } else {
        dVal = moment(value, "DD-MMM-YYYY").diff(
          moment(args.targetValue, "DD-MMM-YYYY").add(args.adjuster, "days"),
          "days"
        );
        tVal = moment(args.targetValue, "DD-MMM-YYYY")
          .add(args.adjuster, "days")
          .format("DD-MMM-YYYY");
      }

      if (args.opt == "lt" && dVal >= 0) {
        rData.data.msg = args.msg ? args.msg : `Date must be before: ${tVal}`;
        rData.valid = false;
        return rData;
      } else if (args.opt == "lteq" && dVal > 0) {
        rData.data.msg = args.msg
          ? args.msg
          : `Date must be on or before: ${tVal}`;
        rData.valid = false;
        return rData;
      } else if (args.opt == "gt" && dVal <= 0) {
        rData.data.msg = args.msg ? args.msg : `Date must be after: ${tVal}`;
        rData.valid = false;
        return rData;
      } else if (args.opt == "gteq" && dVal < 0) {
        rData.data.msg = args.msg
          ? args.msg
          : `Date must be on or after: ${tVal}`;
        rData.valid = false;
        return rData;
      } else if (args.opt == "eq" && dVal != 0) {
        rData.data.msg = args.msg ? args.msg : `Date must be same as: ${tVal}`;
        rData.valid = false;
        return rData;
      } else if (args.opt == "noteq" && dVal == 0) {
        rData.data.msg = args.msg
          ? args.msg
          : `Date must not be equal to: ${tVal}`;
        rData.valid = false;
        return rData;
      }
    } else if (args.dataType == "number") {
      if (args.adjuster === undefined) {
        dVal = +value - +args.targetValue;
        tVal = args.targetValue;
      } else {
        dVal = value - args.targetValue + args.adjuster;
        tVal = args.targetValue + args.adjuster;
      }
      if (args.opt == "lt" && dVal >= 0) {
        rData.data.msg = args.msg
          ? args.msg
          : `This field must be less than: ${tVal}`;
        rData.valid = false;
        return rData;
      } else if (args.opt == "lteq" && dVal > 0) {
        rData.data.msg = args.msg
          ? args.msg
          : `This field must be less or equal to: ${tVal}`;
        rData.valid = false;
        return rData;
      } else if (args.opt == "gt" && dVal <= 0) {
        rData.data.msg = args.msg
          ? args.msg
          : `This field must be greater than: ${tVal}`;
        rData.valid = false;
        return rData;
      } else if (args.opt == "gteq" && dVal < 0) {
        rData.data.msg = args.msg
          ? args.msg
          : `This field must be greater or equal to: ${tVal}`;
        rData.valid = false;
        return rData;
      } else if (args.opt == "eq" && dVal != 0) {
        rData.data.msg = args.msg
          ? args.msg
          : `This field must be equal to: ${tVal}`;
        rData.valid = false;
        return rData;
      } else if (args.opt == "noteq=" && dVal == 0) {
        rData.data.msg = args.msg
          ? args.msg
          : `This field must not be equal to: ${tVal}`;
        rData.valid = false;
        return rData;
      }
    }
    return rData;
  }
};
const required_select = {
  getMessage: () => {
    return "This field is required.";
  },
  validate: value => {
    if (value.value === undefined || value.value === null) {
      return false;
    }
    return true;
  }
};
const valid_date_format = {
  getMessage: () => {
    return "Date format allowed is 01-Jan-2018";
  },
  validate: value => {
    if (value === null || value === undefined) {
      return false;
    }
    let re = new RegExp(
      "^([0]?[1-9]|[1-2]\\d|3[0-1])-(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)-[1-2]\\d{3}$",
      "i"
    );
    if (!re.test(value)) {
      return false;
    }
    return true;
  }
};
const valid_datetime_format = {
  getMessage: () => {
    return "Date-time format allowed is 01-Jan-2018 23:59";
  },
  validate: value => {
    if (value === null || value === undefined) {
      return false;
    }
    let re = new RegExp(
      "^([0]?[1-9]|[1-2]\\d|3[0-1])-(JAN|FEB|MAR|APR|MAY|JUN|JULY|AUG|SEP|OCT|NOV|DEC)-[1-2]\\d{3} [012]{0,1}[0-9]:[0-6][0-9]$",
      "i"
    );
    if (!re.test(value)) {
      return false;
    }
    return true;
  }
};
const valid_time_format = {
  getMessage: () => {
    return "Time format allowed is 23:59";
  },
  validate: value => {
    if (value === null || value === undefined) {
      return false;
    }
    let re = new RegExp("^[012]{0,1}[0-9]:[0-6][0-9]$");
    if (!re.test(value)) {
      return false;
    }
    return true;
  }
};
const valid_ddmm_format = {
  getMessage: () => {
    return "Day-Month format allowed: example 01-Dec";
  },
  validate: value => {
    if (value === null || value === undefined) {
      return false;
    }
    let re = new RegExp(
      "^([0]?[1-9]|[1-2]\\d|3[0-1])-(JAN|FEB|MAR|APR|MAY|JUN|JULY|AUG|SEP|OCT|NOV|DEC)$",
      "i"
    );
    if (!re.test(value)) {
      return false;
    }
    return true;
  }
};
const valid_yyyy_format = {
  getMessage: () => {
    return "Year format allowed is NNNN: example 2018";
  },
  validate: value => {
    if (value === null || value === undefined) {
      return false;
    }
    let re = new RegExp("^[012]{0,1}[0-9]:[0-6][0-9]$", "i");
    if (!re.test(value)) {
      return false;
    }
    return true;
  }
};

VeeValidate.Validator.localize("en", dictionary.en);
VeeValidate.Validator.extend("current_date_compare", current_date_compare);
VeeValidate.Validator.extend("valid_name", valid_name);
VeeValidate.Validator.extend("valid_uk_postcode", valid_uk_postcode);
VeeValidate.Validator.extend("age_check", age_check);
VeeValidate.Validator.extend("lookup_compare", lookup_compare);
VeeValidate.Validator.extend("required_select", required_select);
VeeValidate.Validator.extend("valid_date_format", valid_date_format);
VeeValidate.Validator.extend("valid_datetime_format", valid_datetime_format);
VeeValidate.Validator.extend("valid_time_format", valid_time_format);
VeeValidate.Validator.extend("valid_ddmm_format", valid_ddmm_format);
VeeValidate.Validator.extend("valid_yyyy_format", valid_yyyy_format);

export const VeeVal = VeeValidate;
