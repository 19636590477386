import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueScript2 from "vue-script2";
import VModal from "vue-js-modal";
import { VeeVal } from "./VeeValidateHelper";
import VueMask from "v-mask";
import VueQuillEditor from "vue-quill-editor";
import vSelect from "vue-select";
import VueScrollTo from "vue-scrollto";
import VueGeolocation from "vue-browser-geolocation";
import VueGtag from "vue-gtag";

import MsalInstance from "./plugins/MsalInstance";
import AppConstants from "./plugins/AppConstants";

import "animate.css";
import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css"; // for bubble theme
import "vue-select/dist/vue-select.css";

Vue.use(MsalInstance);
Vue.use(AppConstants);
Vue.use(VueGeolocation);
Vue.use(VeeVal);
Vue.use(VModal);
Vue.use(VueScript2);
Vue.use(VueMask);
Vue.use(VueQuillEditor, {
  modules: {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ align: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }]
    ]
  },
  theme: "snow"
});

Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});
Vue.use(
  VueGtag,
  {
    config: { id: "G-D6MYX9Q9F8" },
    enabled: process.env.NODE_ENV === "production" ? true : false
  },
  router
);

Vue.component("v-select", vSelect);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
