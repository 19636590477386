const AppConstants = {
  install(vue) {
    vue.prototype.$loginRequest = {
      account: null,
      scopes: ["openid", "profile", "User.Read"]
    };
    vue.prototype.$graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
      graphGroupsEndpoint: "https://graph.microsoft.com/v1.0/me/memberOf",
      graphMailEndpoint: "https://graph.microsoft.com/v1.0/me/messages"
    };
    vue.prototype.$tokenRequest = {
      account: null,
      scopes: ["api://84409c95-6b55-49ad-b97d-d0aa57e8244d/full.access"],
      forceRefresh: false
    };
    vue.prototype.$appOptions = {
      accountId: null
    };
  }
};
export default AppConstants;
