import * as msal from "@azure/msal-browser";
const msalConfig = {
  auth: {
    clientId: "3da451d3-60bc-49f3-9b42-eaa6292e74c6",
    authority:
      "https://login.microsoftonline.com/asamaiproperties.onmicrosoft.com",
    redirectUri:
      process.env.NODE_ENV === "production"
        ? "https://asamaiproperties.co.uk/"
        : "http://localhost:8080/"
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
};
const MsalInstance = {
  install(vue) {
    //debugger;
    vue.prototype.$msalInstance = new msal.PublicClientApplication(msalConfig);
  }
};
export default MsalInstance;
