import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import createPersistedState from "vuex-persistedstate";
import * as msal from "@azure/msal-browser";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isAdmin: false,
    isDataEntry: false,
    userAuthenticated: false,
    userFullName: "",
    userInitials: "",
    username: "",
    currentPage: 1,
    showSearch: true
  },
  mutations: {
    setUserInitials(state, userInitials) {
      state.userInitials = userInitials;
    },
    setUserFullName(state, userFullName) {
      state.userFullName = userFullName;
    },
    setUsername(state, username) {
      state.username = username;
    },
    setUserIsAuthenticated: function(state, status) {
      state.userAuthenticated = status;
    },
    setAdminStatus(state, status) {
      state.isAdmin = status;
    },
    setDataEntryStatus(state, status) {
      state.isDataEntry = status;
    },
    setCurrentPage(state, page) {
      state.currentPage = page;
    },
    setSearchToggleState(state, status) {
      state.showSearch = status;
    }
  },
  getters: {
    getUserFullName(state) {
      return state.userFullName;
    },
    getUserInitials(state) {
      return state.userInitials;
    },
    userIsAuthenticated(state) {
      return state.userAuthenticated;
    },
    getUsername(state) {
      return state.username;
    },
    isAdmin(state) {
      return state.isAdmin;
    },
    isDataEntry(state) {
      return state.isDataEntry;
    },
    getCurrentPage(state) {
      return state.currentPage;
    },
    getSearchToggleState(state) {
      return state.showSearch;
    }
  },
  actions: {
    async signIn(context, { vm }) {
      vm.$msalInstance.loginRedirect(vm.$loginRequest);
    },
    signOut(context, { vm }) {
      context.commit("setUserFullName", null);
      context.commit("setUserIsAuthenticated", false);
      context.commit("setAdminStatus", false);
      context.commit("setDataEntryStatus", false);
      const logoutRequest = {
        account: vm.$msalInstance.getAccountByHomeId(vm.$appOptions.accountId)
      };
      vm.$msalInstance.logout(logoutRequest);
    },
    async fetchUserRoles(context, vm) {
      const token = await context.dispatch("getTokenGraph", vm);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      let roles = null;
      ({
        data: { value: roles }
      } = await axios.get(vm.$graphConfig.graphGroupsEndpoint, config));

      context.commit("setAdminStatus", false);
      context.commit("setDataEntryStatus", false);
      //debugger;
      if (roles.find(p => p.displayName === "Admin")) {
        context.commit("setAdminStatus", true);
      } else if (roles.find(p => p.displayName === "DataEntry")) {
        context.commit("setDataEntryStatus", true);
      }
    },
    async getToken(context, { vm }) {
      vm.$tokenRequest.account = vm.$msalInstance.getAccountByHomeId(
        vm.$appOptions.accountId
      );
      //debugger;
      try {
        const { accessToken } = await vm.$msalInstance.acquireTokenSilent(
          vm.$tokenRequest
        );
        return accessToken;
      } catch (error) {
        if (error instanceof msal.InteractionRequiredAuthError) {
          const { accessToken } = await vm.$msalInstance.acquireTokenRedirect(
            vm.$tokenRequest
          );
          return accessToken;
        }
      }
    },
    async getTokenGraph(context, vm) {
      vm.$loginRequest.account = vm.$msalInstance.getAccountByHomeId(
        vm.$appOptions.accountId
      );
      try {
        const { accessToken } = await vm.$msalInstance.acquireTokenSilent(
          vm.$loginRequest
        );
        return accessToken;
      } catch (error) {
        if (error instanceof msal.InteractionRequiredAuthError) {
          const { accessToken } = await vm.$msalInstance.acquireTokenRedirect(
            vm.$loginRequest
          );
          return accessToken;
        }
      }
    },
    async handleAuthenticationRedirect(context, { vm, response }) {
      //debugger;
      if (response) {
        vm.$appOptions.accountId = response.account.homeAccountId;
        context.commit("setUsername", response.account.username);
        context.commit("setUserIsAuthenticated", true);
        context.commit("setUserFullName", response.idTokenClaims.name);
        await context.dispatch("fetchUserRoles", vm);
      } else {
        const currentAccounts = vm.$msalInstance.getAllAccounts();
        if (!currentAccounts || currentAccounts.length < 1) {
          return;
        } else if (currentAccounts.length > 1) {
          console.warn("Multiple accounts detected.");
        } else if (currentAccounts.length === 1) {
          vm.$appOptions.accountId = currentAccounts[0].homeAccountId;
        }
      }
    }
  },
  plugins: [
    createPersistedState({
      key: "ASPRWebAppSession",
      storage: window.sessionStorage
    })
  ]
});
