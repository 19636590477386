<template>
  <div>
    <HeaderMain></HeaderMain>
    <router-view></router-view>
    <FooterMain></FooterMain>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  components: {
    HeaderMain: () => import("./components/HeaderMain"),
    FooterMain: () => import("./components/FooterMain")
  },
  methods: {
    ...mapActions(["handleAuthenticationRedirect"])
  },
  mounted() {
    this.$msalInstance
      .handleRedirectPromise()
      .then(response => {
        // console.log("Hello 1");
        // console.log(response);
        this.handleAuthenticationRedirect({ vm: this, response });
      })
      .catch(error => {
        console.error(error);
      });
  }
};
</script>
